import type { FunctionComponent } from 'react';
import { StarRating } from '../star-rating';
import styles from './app-store-rating.module.scss';
export const AppStoreRating: FunctionComponent<React.PropsWithChildren<Props>> = ({
  value,
  numberOfReviews,
  ...restOfProps
}) => <div className={styles.section} {...restOfProps} data-sentry-component="AppStoreRating" data-sentry-source-file="index.tsx">
        <div className={styles.rating}>
            <StarRating color='step-white' value={value} starSize='small' label={`Average AppStore rating is ${value} out of 5 with ${numberOfReviews} reviews`} data-sentry-element="StarRating" data-sentry-source-file="index.tsx" />
        </div>
        <div className={styles.reviews}>
            <b>App&nbsp;Rating</b>
            <br />
            {numberOfReviews.toLocaleString()}&nbsp;Reviews
        </div>
    </div>;
interface Props {
  /** Rated out of 5 */
  value: number;
  /** How many people have reviewed this. */
  numberOfReviews: number;
}